/* eslint-disable camelcase */
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import AxiosClient from 'utils/AxiosClient';

export interface QRRecord {
  ticket: string;
  expires_at: number;
}

export interface ClerkRecord {
  bind_id: string;
  wechat: {
    avatar_url: string;
    nickname: string;
  };
  is_manager: boolean;
  active: boolean;
}

interface RequestQRResponse extends QRRecord {
  succeeded: boolean;
}

interface ListClerksResponse {
  succeeded: boolean;
  clerks: ClerkRecord[];
}

interface UpdateClerkResponse {
  clerk: ClerkRecord;
}

interface UnbindClerkResponse {
  deleted: boolean;
  snapshot: ClerkRecord;
}

export const flashcardClient = new AxiosClient({
  baseURL: process.env.REACT_APP_FLASHCARD_API,
});

export function authFlashcardClient(token: string) {
  return flashcardClient.patchConfig({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function requestQRCode(config?: AxiosRequestConfig) {
  return flashcardClient.request<RequestQRResponse>({
    ...config,
    url: '/fishpond/qr',
    method: 'POST',
  });
}

export function listClerks(config?: AxiosRequestConfig) {
  return flashcardClient.request<ListClerksResponse>({
    ...config,
    url: '/fishpond/clerks',
    method: 'GET',
  });
}

export function updateClerk(bid: string, data: ClerkRecord, config?: AxiosRequestConfig) {
  return flashcardClient.request<UpdateClerkResponse>({
    ...config,
    url: `/fishpond/clerks/${bid}`,
    method: 'PUT',
    data,
  });
}

export function unbindClerk(bid: string, config?: AxiosRequestConfig) {
  return flashcardClient.request<UnbindClerkResponse>({
    ...config,
    url: `/fishpond/clerks/${bid}`,
    method: 'DELETE',
  });
}

interface ShopBasic {
  // 店铺主账号
  sellerNick: string;
  // 店铺ID
  sid: string;
  // 店铺名称
  title: string;
}

interface ShopSeats {
  // 已授权客服机器人数量
  activeSeats: number;
  // 客服总数
  totalSeats: number;
}

interface ShopExtra {
  hasDialogueLite: boolean;
  isProxy: boolean;
}

interface FailedResponse {
  success: false;
  reason: string;
}
type SuccessfulResponse<T> = {
  success?: true;
} & T;

type FlashCardResponse<T = void> = FailedResponse | SuccessfulResponse<T>;

const normalizeResponse = <T = void>({ data }: AxiosResponse<FlashCardResponse<T>>) => {
  if (data?.success === false) {
    throw new Error(data.reason);
  }
  const { success, ...rest } = data;
  return rest;
};

export enum TaobaoStatus {
  // 正常
  Active = 1,
  // 已停用
  Stale = 2,
  // 已冻结，阿里官方冻结
  Frozen = 3,
}

interface Permission {
  // ALERT: 机器人授权只存在一侧环境
  // PRE 客户端机器人授权
  clientChatBot: boolean;
  // PRG 千牛小程序机器人授权
  miniChatBot: boolean;
  // lxx, lxk 授权
  clientLxx: boolean;
}

export interface Assistant {
  // 内部客服ID
  id: number;
  // 淘宝侧客服的ID
  subId: string;
  // 是否是子账号
  isSub: boolean;
  // 客服账号全称，主账号和sellerNick, nick一样，子账号是{sellerNick}:{nick}
  prefixed_nick: string;
  // 客服账号名称
  nick: string;
  // 客服权限合集
  permission: Permission;
  // 客服账号在淘宝的状态，ALERT: 已删除的账号不会从接口获取到
  taobao_status: TaobaoStatus;
}

export interface Shop extends ShopBasic, ShopSeats, ShopExtra {}
export interface ShopWithAssistants extends Shop {
  assistants: Assistant[];
}

/**
 * 群店授权机器人状态
 */
export interface CustomerSeats {
  customerActiveSeats: number;
  customerTotalSeats: number;
}

export interface CustomerShops<T> extends Partial<CustomerSeats> {
  currentShop?: T;
  shops: T[];
}

/**
 * 获取指定店铺所有客服信息
 * @param sid 店铺ID，不指定获取当前店铺
 */
export function listAssistants(sid?: string) {
  // return flashcardClient
  //   .get<FlashCardResponse<ShopWithAssistants>>('shop/assistant', {
  //     ...(sid ? { params: { sid } } : {}),
  //   })
  //   .then(normalizeResponse);
  return flashcardClient
    .request<FlashCardResponse<ShopWithAssistants>>({
      url: '/shop/assistant',
      method: 'GET',
      params: sid ? { sid } : {},
    })
    .then(normalizeResponse);
}

/**
 * 从淘宝同步指定店铺客服账号 = 在服务内CRUD客服
 * @param sid 需要同步的店铺，不指定时同步当前店铺
 */
export function syncAssistants(sid?: string) {
  return flashcardClient
    .request<FlashCardResponse<ShopWithAssistants & CustomerSeats>>({
      method: 'PUT',
      url: 'shop/assistant',
      data: {},
    })
    .then(normalizeResponse);
}

const token = localStorage.getItem('token');

if (token) {
  authFlashcardClient(token);
}
