import React, { FunctionComponent, ComponentProps, CSSProperties } from 'react';
import {
  Icon, Tooltip, Statistic,
} from 'antd';
import styles from './LabelledNumber.module.scss';

interface ILabelledNumber extends ComponentProps<any> {
  label: string,
  rmb?: boolean,
  value: number,
  help?: string,
  blue?: boolean,
  small?: boolean,
  labelStyle?: CSSProperties,
  valueStyle?: CSSProperties,
  style?: CSSProperties,
}

const LabelledNumber: FunctionComponent<ILabelledNumber> = ({
  label,
  rmb,
  value,
  help,
  blue,
  small,
  labelStyle,
  valueStyle,
  style: styleProp,
  ...otherProps
}) => {
  const title = (
    <span
      style={labelStyle}
      className={small ? styles.smallLabel : ''}
    >
      <span>{label}</span>
      {help
        ? (
          <Tooltip placement="right" title={help}>
            <Icon type="question-circle" style={{ margin: '0 .25rem' }} />
          </Tooltip>
        ) : null}
    </span>
  );

  let style;
  if (blue) {
    style = {
      color: '#178FFE',
      ...valueStyle,
    };
  } else {
    if (small) {
      style = {
        fontSize: '1.125rem',
        ...valueStyle,
      };
    }
    style = valueStyle;
  }
  return (
    <Statistic
      {...otherProps}
      title={title}
      style={{ minWidth: '7rem', maxWidth: '100%', ...styleProp }}
      valueStyle={style}
      {
      ...value === -1
        ? {
          valueRender: () => ' - ',
        }
        : {
          value: rmb ? value / 100 : value,
          precision: rmb
            ? 2
            : 0,
          prefix: rmb
            ? (
              <span>¥</span>
            )
            : null,
        }
      }
    />
  );
};

export default LabelledNumber;
