import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import { StateProvider } from './hooks/useGlobalState';
import { rootReducer, initialRootState } from './reducer';
import App from './views/App/App';
import 'moment/locale/zh-cn';
import './styles/global.scss';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

moment.locale('zh-cn');
ReactDOM.render((
  <ConfigProvider locale={locale}>
    <StateProvider initialState={initialRootState} reducer={rootReducer}>
      <App />
    </StateProvider>
  </ConfigProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
