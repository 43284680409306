import { authReducer, initialAuthState } from './auth';
import { assistantGroupReducer, initialAssistantGroupState } from './assistantGroup';
import { shopInfoReducer, initialShopInfo } from './shopInfo';
// import planReducer from './reducer/plan'

export const rootReducer: IReducer = ({ auth, assistantGroups, shopInfo }, action) => {
  return {
    auth: authReducer(auth, action),
    assistantGroups: assistantGroupReducer(assistantGroups, action),
    shopInfo: shopInfoReducer(shopInfo, action),
    // plan: planReducer(plan, action)
  };
};
export const initialRootState = {
  ...initialAuthState,
  ...initialAssistantGroupState,
  ...initialShopInfo,
};
