import React, { FunctionComponent } from 'react';
import { Popover, Icon } from 'antd';

import styles from './HintPopIcon.module.scss';

const HintPopIcon: FunctionComponent<{
  hint: string
}> = ({
  hint,
}) => {
  return (
    <Popover
      title={null}
      content={(
        <div className={styles.title}>{hint}</div>
      )}
    >
      <Icon type="question-circle" style={{ margin: '0 .25rem' }} />
    </Popover>
  );
};

export default HintPopIcon;
