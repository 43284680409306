import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

interface IconedLabelProps extends Pick<IconProps, 'component'> {
  icon?: string, // antd Icon type
  color?: string, // antd Icon theme twoToneColor
  title?: string,
  link?: string,
  onClick?(event: any): any,
  className?: string,
}

const renderAction = (props: IconedLabelProps) => {
  const {
    icon,
    color,
    title = '',
    component,
  } = props;

  const requiredProps = icon ? { type: icon } : { component };

  return (
    <>
      {
      (icon || component) || title ? (
        <span style={
        title
          ? { margin: '0 .25rem' }
          : { margin: '0 .5rem' }
        }
        >
          {color
            ? <Icon {...requiredProps} theme="twoTone" twoToneColor={color} />
            : <Icon {...requiredProps} />}
          {title
            ? <span style={{ margin: '0 .25rem' }}>{title}</span>
            : null}
        </span>
      ) : null
    }
    </>
  );
};

const IconedLabel: FunctionComponent<IconedLabelProps> = ({
  link,
  icon,
  color,
  title,
  onClick,
  component,
  ...otherProps
}) => {
  return (
    <span
      onClick={onClick}
      {...otherProps}
    >
      {link
        ? (
          <Link to={link}>
            { renderAction({
              icon,
              color,
              title,
              component,
            }) }
          </Link>
        )
        : renderAction({
          icon,
          color,
          title,
          component,
        })}
    </span>
  );
};

export default IconedLabel;
