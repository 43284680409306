import React, { FunctionComponent } from 'react';
import { Divider } from 'antd';

const PlanFormTitle:FunctionComponent<{
  title: string
}> = ({ title }) => {
  return (
    <>
      <h4>{title}</h4>
      <Divider style={{ marginTop: 0 }} />
    </>
  );
};
export default PlanFormTitle;
