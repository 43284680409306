const plan = {
  custom: {
    title: '自定义营销',
    titleFull: '自定义营销计划',
    description: '营销计划由一个或多个任务组成，任务是通过圈定人群发送话术的营销方式。商家可通过营销计划比较任务数据。',
  },
  auto: {
    title: '自动化营销',
  },
  task: {
    impressonPopulation: '送达人数',
    state: {
      failed: '系统自动停止',
      userStoped: '商家手动操作停止',
    },
    form: {
      startTimeTooEarly: '任务需要5分钟准备，已默认修改开始时间为5分钟后',
      endTimeTooEarly: '任务结束时间需大于开始时间，已默认修改为5分钟后',
      within48hours: '发送时间间隔必须48小时以内',
    },
  },
};
const user = {
  group: {
    coverPopulation: '覆盖人数',
    reachblePopulation: '预计安全触达',
    populationUnit: '人',
    coverRule: '覆盖规则',
    coverPopulationHint: '历史咨询或下单的买家中满足当前筛选规则的人数',
    reachblePopulationHint: '在上述基础上基于风控机制筛选后的预估触达人数，自动剔除近30天无咨询/下单的买家、30天已营销过买家、黑名单等',
  },
};
const global = {
  confirm: '确认',
  cancel: '取消',
  action: '可执行操作',
};

const translation = {
  global,
  user,
  plan,
};
export default translation;
