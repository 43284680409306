import React, { FunctionComponent } from 'react';
import generateTags from 'utils/generateTags';
import ConditionTag from './ConditionTag';
import Keyword from './Keyword';

const UserGroupTagGroup: FunctionComponent<{
  userGroup: IUserGroup,
  keyword?: string
}> = ({ userGroup, keyword }) => {
  const {
    // conditionGroupId,
    conditions,
    exclusiveConditions,
    operator,
    // userGroupId,
    // userGroupName
  } = userGroup;

  const generatedConditions = generateTags(conditions || {});
  const generatedExclusiveConditions = generateTags(exclusiveConditions || {});
  let type: 'and' | 'or' | 'not' | undefined;
  if (operator === 1) {
    if (generatedConditions.length === 1) {
      type = 'and' as 'and';
    } else {
      type = 'or' as 'or';
    }
  } else {
    type = 'and' as 'and';
  }

  return (
    <div>
      {
        generatedConditions.map((condition) => {
          return (
            <ConditionTag
              type={type}
              key={condition.name}
            >
              {
                keyword ? (
                  <Keyword full={condition.shortRuleName} keyword={keyword} />
                ) : condition.shortRuleName
              }
            </ConditionTag>
          );
        })
      }
      {
        generatedExclusiveConditions.map((condition) => {
          return (
            <ConditionTag
              type="not"
              key={condition.name}
            >
              {
                keyword ? (
                  <Keyword full={condition.shortRuleName} keyword={keyword} />
                ) : condition.shortRuleName
              }
            </ConditionTag>
          );
        })
      }
    </div>
  );
};
export default UserGroupTagGroup;
