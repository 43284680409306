import React, { FunctionComponent } from 'react';
import { Tooltip } from 'antd';
import IconedLabel from './IconedLabel';
import translation from '../config/translation';

interface IState {
  state: number,
  hasIcon?: boolean
}

const taskStateNames = {
  todo: '待发送',
  doing: '发送中',
  done: '已发送',
  failed: '发送失败',
  stoped: '已终止',
};

const dotSvg = (fill: string) => (
  <svg width="6px" height="calc(.5em + 3px)" fill={fill}>
    <g>
      <circle cx="3" cy="3" r="3" />
    </g>
  </svg>
);

const TaskStates = {
  todo: 1,
  doing: 2,
  done: 3,
  failed: 4,
  stoped: 5,
};

const State: FunctionComponent<IState> = ({ state, hasIcon }) => {
  const failedContent = (
    <Tooltip placement="top" title={translation.plan.task.state.failed}>
      <IconedLabel component={() => dotSvg('#FF0000')} title={taskStateNames.stoped} />
    </Tooltip>
  );
  if (hasIcon) {
    switch (state) {
      case TaskStates.todo:
        return <IconedLabel component={() => dotSvg('#A7ABC3')} title={taskStateNames.todo} />;
      case TaskStates.doing:
        return <IconedLabel component={() => dotSvg('#FAAD14')} title={taskStateNames.doing} />;
      case TaskStates.done:
        return <IconedLabel component={() => dotSvg('#52C41A')} title={taskStateNames.done} />;
      case TaskStates.failed:
        return failedContent;
      case TaskStates.stoped:
        return <IconedLabel component={() => dotSvg('#FF0000')} title={taskStateNames.stoped} />;
      default:
        return <IconedLabel component={() => dotSvg('#A7ABC3')} title="-" />;
    }
  } else {
    switch (state) {
      case TaskStates.todo:
        return <span>{taskStateNames.todo}</span>;
      case TaskStates.doing:
        return <span>{taskStateNames.doing}</span>;
      case TaskStates.done:
        return <span>{taskStateNames.done}</span>;
      case TaskStates.failed:
        return failedContent;
      case TaskStates.stoped:
        return <span>{taskStateNames.stoped}</span>;
      default:
        return <span>-</span>;
    }
  }
};

export default State;
export {
  TaskStates,
};
