import React, { FC } from 'react';
import styles from './MenuHeader.module.scss';

interface MenuHeaderProps {
  title: string
}

const MenuHeader: FC<MenuHeaderProps> = ({
  title = '',
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.left} />
      <div className={styles.right}>
        <div className={styles.logo} />
        <div className={styles.shopTitle}>{title}</div>
      </div>
    </div>
  );
};

export default MenuHeader;
