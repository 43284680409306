import React, {
  useState, useEffect, FunctionComponent, useCallback,
} from 'react';
import {
  Form, Card, Input, Switch, Select, DatePicker, Breadcrumb, Button,
  message, Icon, Alert, Popconfirm,
} from 'antd';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import moment, { Moment } from 'moment';
import {
  Page, UserGroupSelector, StatisticInLine, HintPopIcon,
} from 'components';
import {
  getAssistantGroups, createPlan, createTask, getPlan,
  updateTask, countConditions, defaultExtraConditions,
} from 'api/lxxApi';
import useDebounce from 'hooks/useDebounce';
import translation from 'config/translation';
import PlanFormTitle from './PlanFormTitle';

const { Option } = Select;
const { RangePicker } = DatePicker;

enum PlanEditorMode {
  CreatePlan = 'CreatePlan',
  CreateTask = 'CreateTask',
  // EditPlan, // 计划详情页面modal修改计划内容
  EditTask = 'EditTask',
  // CopyPlan,
  CopyTask = 'CopyTask'
}

enum EditingState {
  Other = 'Other',
  UserGroup = 'UserGroup',
  Rules = 'Rules'
}

interface IPlanEditor extends RouteComponentProps<{
  planId?: string,
  taskId?: string
}> {
  mode: PlanEditorMode
}

const disabledDate = (current: Moment | null) => {
  if (current) {
    return moment(current).diff(moment.now(), 'day') < 0;
  }
  return false;
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const PlanEditor: FunctionComponent<IPlanEditor> = ({
  mode, history, match, ...otherProps
}) => {
  const onCancel = useCallback(() => { history.push('/plans'); }, [history]);
  const { planId, taskId } = match.params;

  // 编辑器使用的一些信息
  const [config, setConfig] = useState({
    title: '',
    isPlanEditable: false,
    isTaskEditable: false,
  });
  const [editingState, setEditingState] = useState<EditingState>(EditingState.Other);

  // 根据模式初始化一些信息
  useEffect(() => {
    switch (mode) {
      case PlanEditorMode.CreatePlan:
        setConfig({
          ...config, title: '新建自定义营销计划', isPlanEditable: true, isTaskEditable: true,
        });
        break;
      case PlanEditorMode.CreateTask:
        setConfig({
          ...config, title: '新建营销任务', isPlanEditable: false, isTaskEditable: true,
        });
        break;
      case PlanEditorMode.EditTask:
        setConfig({
          ...config, title: '编辑营销任务', isPlanEditable: false, isTaskEditable: true,
        });
        break;
      case PlanEditorMode.CopyTask:
        setConfig({
          ...config, title: '复制营销任务', isPlanEditable: false, isTaskEditable: true,
        });
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, planId, taskId]);

  const [loadingPlan, setLoadingPlan] = useState(false);
  const [loadingAssistantGroups, setLoadingAssistantGroups] = useState(false);
  const [requesting, setRequesting] = useState(false);
  // 客服组列表
  const [assistantGroups, setAssistantGroups] = useState([]);
  // 计划名称
  const [planName, setPlanName] = useState<string>('');
  // 子任务名称
  const [taskName, setTaskName] = useState<string>('');
  // 唯一的用户群ID
  const [userGroupId, setUserGroupId] = useState<number>(0);
  // 额外筛选规则
  const [extraConditions, setExtraConditions] = useState<any>(defaultExtraConditions);
  // 规则类型
  const [ruleType, setRuleType] = useState<number>(1);
  // 发送内容
  const [sendContents, setSendContents] = useState<string[]>(['']);
  const handleContentChange = (index: number, value: string): void => {
    const newContents: string[] = [...sendContents];
    newContents[index] = value;
    setSendContents(newContents);
  };
  // 发送时间
  const [sendTime, setSendTime] = useState<Moment>(moment().add(10, 'minutes'));
  // 终止发送时间
  const [endTime, setEndTime] = useState<Moment>(moment().add(24, 'hours'));
  // 唯一客服组ID
  const [assistantGroupId, setAssistantGroupId] = useState<string>();
  // 搜索客服组
  const [searchAssistantGroupTerm, setSearchAssistantGroupTerm] = useState<string>();
  const denbouncedSearchAssistantGroupTerm = useDebounce(searchAssistantGroupTerm, 500);
  // 是否要跳转回计划列表
  const [shouldRedirect, setShouldRedirect] = useState(false);
  // 编辑、复制子任务时读取的任务
  const [plan, setPlan] = useState<IPlan>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [taskList, setTaskList] = useState<Array<IPlanTask>>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [task, setTask] = useState<IPlanTask>();
  // 覆盖人数
  const [conditionsCount, setConditionsCount] = useState(0);
  // 触达人数
  const [conditionsReachableCount, setConditionsReachableCount] = useState(0);

  // 获取客服组列表
  useEffect(() => {
    let didCancel = false;
    if (!loadingAssistantGroups) {
      setLoadingAssistantGroups(true);
      getAssistantGroups({
        keyword: searchAssistantGroupTerm,
      })
        .then((response) => {
          if (response.data.code === 'SUCCESS') {
            if (!didCancel) {
              setAssistantGroups(response.data.data);
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch(() => {
          message.error('获取客服组列表失败');
        })
        .finally(() => {
          if (!didCancel) {
            setLoadingAssistantGroups(false);
          }
        });
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [denbouncedSearchAssistantGroupTerm]);

  useEffect(() => {
    let didCancel = false;
    // 创建计划什么都不需要
    if (mode === PlanEditorMode.CreatePlan) { return; }
    if (!loadingPlan) {
      // 创建任务planId必须
      if (!planId) {
        setShouldRedirect(true);
        return;
      }
      // 复制、编辑计划taskId必须
      if ((mode === PlanEditorMode.CopyTask || mode === PlanEditorMode.EditTask) && !taskId) {
        setShouldRedirect(true);
        return;
      }
      setLoadingPlan(true);
      getPlan(planId)
        .then((response) => {
          if (response.data.code === 'SUCCESS') {
            if (!didCancel) {
              const { plan: newPlan, taskList: newTaskList } = response.data.data;
              setPlan(newPlan);
              setTaskList(newTaskList);
              if (mode !== PlanEditorMode.CreateTask) {
                const filteredTaskList = newTaskList.filter((task: IPlanTask) => {
                  return task.taskId === taskId;
                });
                if (filteredTaskList.length === 0) {
                  if (mode === PlanEditorMode.CopyTask) {
                    message.error('需要复制的任务已不存在');
                  }
                  if (mode === PlanEditorMode.EditTask) {
                    message.error('需要编辑的任务已不存在');
                  }
                  setShouldRedirect(true);
                } else {
                  const newTask = filteredTaskList[0];
                  setTask(newTask);
                  if (mode === PlanEditorMode.CopyTask) {
                    setTaskName(`${newTask.taskName}（新）`);
                  } else {
                    setTaskName(newTask.taskName);
                  }
                  setUserGroupId(newTask.userGroupId);
                  if (newTask.filter) {
                    setExtraConditions(newTask.filter.conditions || defaultExtraConditions);
                  } else {
                    setExtraConditions(defaultExtraConditions);
                  }
                  setRuleType(newTask.ruleType);
                  setSendTime(moment(newTask.sendTime));
                  setEndTime(moment(newTask.endTime));
                  setSendContents(newTask.sendContents);
                  setAssistantGroupId(newTask.assistantId);
                }
              }
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch(() => {
          message.error('获取计划失败，返回计划列表');
          if (!didCancel) {
            setShouldRedirect(true);
          }
        })
        .finally(() => {
          if (!didCancel) {
            setLoadingPlan(false);
          }
        });
    }
    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, planId, taskId]);

  useEffect(() => {
    let didCancel = false;
    if (!userGroupId) {
      return;
    }
    const emptyCondtionGroup: TConditionGroup = {};
    countConditions({
      conditions: emptyCondtionGroup,
      operator: 0,
    }, {
      conditions: extraConditions,
    }, ruleType, userGroupId)
      .then((response: any) => {
        const { code, message: _message, data } = response.data;
        if (code === 'SUCCESS') {
          if (!didCancel) {
            setConditionsCount(data.count);
            setConditionsReachableCount(data.coverCount);
          }
        } else {
          message.error(_message);
        }
      });
    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [userGroupId, extraConditions, ruleType]);

  if (shouldRedirect) {
    return <Redirect to="/plans" />;
  }

  const save = () => {
    if (mode === PlanEditorMode.CreatePlan) {
      if (!planName) {
        message.error('计划名称必填');
        return;
      }
    }
    if (!taskName) {
      message.error('任务名称必填');
      return;
    }
    if (!userGroupId) {
      message.error('用户群必填');
      return;
    }
    let isContentInvalid = false;
    if (!sendContents || !sendContents.length) {
      isContentInvalid = true;
    }
    sendContents.forEach((content) => {
      if (!content) {
        isContentInvalid = true;
      }
    });
    if (isContentInvalid) {
      message.error('发送内容必填');
      return;
    }
    if (!sendTime || !endTime) {
      message.error('发送时间必选');
      return;
    }
    let validSendTime: moment.Moment = sendTime;
    let validEndTime: moment.Moment = endTime;
    if (moment(sendTime).diff(moment(), 'minutes') < 5) {
      validSendTime = moment().add(5, 'minutes');
      setSendTime(validSendTime);
      message.info(translation.plan.task.form.startTimeTooEarly, 5);
    }
    if (validSendTime > endTime) {
      validEndTime = moment(sendTime).add(5, 'minutes');
      setEndTime(validEndTime);
      message.info(translation.plan.task.form.endTimeTooEarly, 5);
    }
    if (moment(endTime).diff(moment(sendTime), 'seconds') > 172800) {
      message.error(translation.plan.task.form.within48hours);
      return;
    }
    if (!assistantGroupId) {
      message.error('客服组必选');
      return;
    }
    switch (mode) {
      case PlanEditorMode.CreatePlan: {
        const plan: IPlanNew = {
          planName,
          templateTypeId: 0,
          isCustomized: true,
          task: {
            taskName,
            userGroupId,
            sendContents,
            sendTime: validSendTime.toDate().getTime(),
            endTime: validEndTime.toDate().getTime(),
            assistantId: assistantGroupId,
            filter: {
              conditions: extraConditions,
            },
            ruleType,
          },
        };
        setRequesting(true);
        createPlan(plan)
          .then((response) => {
            if (response.data.code === 'SUCCESS') {
              message.success('新计划创建成功');
              const createdPlan = response.data.data.plan as IPlan;
              history.push(`/plan/${createdPlan.planId}`);
            } else {
              message.error(response.data.message);
            }
          }).catch((e) => {
            message.error('计划创建失败');
          })
          .finally(() => {
            setRequesting(false);
          });
        break;
      }
      case PlanEditorMode.CopyTask:
      case PlanEditorMode.CreateTask: {
        const task: IPlanTaskNew = {
          taskName,
          userGroupId,
          sendContents,
          sendTime: sendTime.toDate().getTime(),
          endTime: endTime.toDate().getTime(),
          assistantId: assistantGroupId,
          filter: {
            conditions: extraConditions,
          },
          ruleType,
        };
        if (!planId || !plan || plan.planId !== planId) {
          message.error('包含该任务的计划可能不存在');
          setShouldRedirect(true);
          return;
        }
        setRequesting(true);
        createTask(planId, task)
          .then((response) => {
            if (response.data.code === 'SUCCESS') {
              message.success('新任务创建成功');
              history.push(`/plan/${planId}`);
            } else {
              message.error(response.data.message);
            }
          })
          .catch(() => [
            message.error('新任务创建失败，请稍后重试'),
          ])
          .finally(() => {
            setRequesting(false);
          });
      }
        break;
      case PlanEditorMode.EditTask: {
        const task: IPlanTaskNew = {
          taskName,
          userGroupId,
          sendContents,
          sendTime: sendTime.toDate().getTime(),
          endTime: endTime.toDate().getTime(),
          assistantId: assistantGroupId,
          filter: {
            conditions: extraConditions,
          },
          ruleType,
        };
        if (!planId || !taskId || !plan || plan.planId !== planId) {
          message.error('包含该任务的计划或任务本身可能不存在');
          setShouldRedirect(true);
          return;
        }
        setRequesting(true);
        updateTask(taskId, task)
          .then((response) => {
            if (response.data.code === 'SUCCESS') {
              message.success('任务编辑成功');
              history.push(`/plan/${planId}`);
            } else {
              message.error(response.data.message);
            }
          })
          .catch(() => {
            message.error('任务编辑失败，请稍后重试');
          });
      }
        break;
      default:
    }
  };

  return (
    <Page>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/plans">{translation.plan.custom.titleFull}</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{config.title}</Breadcrumb.Item>
      </Breadcrumb>
      <h1>{config.title}</h1>
      {
        config.isPlanEditable ? (
          <p>{translation.plan.custom.description}</p>
        ) : null
      }
      <Card style={{ maxWidth: '36rem' }}>
        <Form {...formItemLayout}>
          {
            config.isPlanEditable
              ? (
                <>
                  <PlanFormTitle
                    title="计划详情"
                  />
                  <Form.Item
                    required
                    colon
                    label="计划名称"
                  >
                    <Input
                      required
                      placeholder="请填写计划名称"
                      value={planName}
                      onChange={(e) => setPlanName(e.target.value)}
                    />
                  </Form.Item>
                </>
              ) : null
          }
          {
            config.isTaskEditable
              ? (
                <>
                  <PlanFormTitle
                    title="任务详情"
                  />
                  <Form.Item
                    required
                    colon
                    label="任务名称"
                  >
                    <Input
                      required
                      placeholder="请填写任务名称"
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    colon
                    label="用户群"
                  >
                    <UserGroupSelector
                      history={history}
                      onClick={() => { setEditingState(EditingState.UserGroup); }}
                      active={editingState === EditingState.UserGroup}
                      value={userGroupId}
                      onSelect={(id: number) => setUserGroupId(id)}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    colon
                    label="发送时间"
                  >
                    <RangePicker
                      showTime
                      allowClear={false}
                      separator="至"
                      disabledDate={disabledDate}
                      style={{ width: '100%' }}
                      value={[sendTime, endTime]}
                      onChange={(dates) => {
                        if (dates[0]) {
                          setSendTime(dates[0]);
                        }
                        if (dates[1]) {
                          setEndTime(dates[1]);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    colon
                    label="客服组"
                  >
                    <Select
                      value={assistantGroupId}
                      onChange={(value: string | undefined) => setAssistantGroupId(value)}
                      showSearch
                      onSearch={(value: string) => {
                        setSearchAssistantGroupTerm(value);
                      }}
                      optionFilterProp="children"
                      filterOption={(input: string, option: React.ReactElement) => {
                        // TODO: should return all as API should handle this
                        // if (option.props.children.includes(input))
                        return option.props.children;
                        // else return
                      }}
                    >
                      {
                        assistantGroups.map((assistantGroup: TAssistantGroup) => (
                          <Option key={assistantGroup.id}>
                            {assistantGroup.name}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    required
                    colon
                    label="发送内容"
                  >
                    {
                      sendContents.map((content, index) => (
                        <div
                          style={{ width: '100%', position: 'relative', marginTop: '.25rem' }}
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                        >
                          <Input.TextArea
                            style={{ width: 'calc(100% - 2.5rem)' }}
                            required
                            placeholder="请填写发送内容"
                            value={content}
                            onChange={(e: any) => handleContentChange(index, e.target.value)}
                          />
                          {
                            index ? (
                              <Popconfirm
                                title="确认删除所选话术？"
                                onConfirm={() => {
                                  const contents = [...sendContents];
                                  contents.splice(index, 1);
                                  setSendContents(contents);
                                }}
                                okText={translation.global.confirm}
                                cancelText={translation.global.cancel}
                              >
                                <Button
                                  style={{
                                    position: 'absolute', top: 0, right: 0, padding: '0 .5rem',
                                  }}
                                >
                                  <Icon type="delete" />
                                </Button>
                              </Popconfirm>
                            ) : null
                          }
                        </div>
                      ))
                    }
                    <Button
                      onClick={() => { setSendContents([...sendContents, '']); }}
                    >
                      <Icon type="plus" />
                      <span>
                        添加回复
                      </span>
                    </Button>
                    <span>（为防止账号被禁言，支持多条话术随机发送）</span>
                    <Alert
                      message="严禁发送国家法律法规、淘宝平台禁止的内容，发送内容所有责任全部由商家承担！"
                      type="warning"
                      showIcon
                    />
                  </Form.Item>
                  <Form.Item
                    colon
                    label="风险控制"
                  >
                    <Switch
                      defaultChecked
                      disabled
                    />
                    <span style={{ marginLeft: '.5rem' }}>默认开启官方防骚扰机制</span>
                  </Form.Item>

                  <Form.Item
                    label={translation.user.group.coverPopulation}
                  >
                    <StatisticInLine
                      style={{ display: 'inline-block' }}
                      unit={translation.user.group.populationUnit}
                      value={conditionsCount}
                    />
                    <HintPopIcon hint={translation.user.group.coverPopulationHint} />
                  </Form.Item>
                  <Form.Item
                    label={translation.user.group.reachblePopulation}
                  >
                    <StatisticInLine
                      style={{ display: 'inline-block' }}
                      unit={translation.user.group.populationUnit}
                      value={conditionsReachableCount}
                    />
                    <HintPopIcon hint={translation.user.group.reachblePopulationHint} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={loadingAssistantGroups || requesting}
                      onClick={save}
                    >
                      保存
                    </Button>
                    <span style={{ margin: '.25rem' }} />
                    <Button onClick={onCancel}>取消</Button>
                  </Form.Item>
                </>
              ) : null
          }
        </Form>
      </Card>
    </Page>
  );
};

export default PlanEditor;
export {
  PlanEditorMode,
};
