/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState, useEffect, FunctionComponent, useMemo,
} from 'react';
import {
  Card, Input, Table, Tag, message,
} from 'antd';
import { getUserGroups, getUserGroup } from 'api/lxxApi';
import styles from './UserGroupSelector.module.scss';
import sideEditorStyles from '../SideEditor/SideEditor.module.scss';
import Loading from '../Loading';
import UserGroupTagGroup from '../UserGroupTagGroup';
import Keyword from '../Keyword';

const { Search } = Input;

type TUserGroup = {
  userGroupId: number
  userGroupName: string
}

interface IUserGroupSelector {
  active: boolean,
  value: number,
  onSelect: CallableFunction,
  history: any,
  [x: string]: any
}

const UserGroupSelector: FunctionComponent<IUserGroupSelector> = ({
  history, active, value, onSelect, ...otherProps
}) => {
  const [userGroups, setUserGroups] = useState<Array<TUserGroup>>();
  // 分页-页号
  const [page, setPage] = useState<number>(1);
  // 分页-每页个数
  const [perPage, setPerPage] = useState<number>(10);
  // 分页 计划总数
  const [totalItems, setTotalItems] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSelectedUserGroup, setLoadingSelectedUserGroup] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [userGroup, setUserGroup] = useState<TUserGroup>();

  const columns = [
    {
      key: 'userGroupName',
      dataIndex: 'userGroupName',
      title: '用户群名称',
      render: ((name:string) => {
        return <Keyword full={name} keyword={keyword} />;
      }),
    }, {
      key: 'condititions',
      title: '筛选规则',
      render: (record: IUserGroup) => {
        return <UserGroupTagGroup userGroup={record} keyword={keyword} />;
      },
    },
  ];

  useEffect(() => {
    let didCancel = false;
    setLoading(true);
    const params: any = {
      perPage, page,
    };
    if (keyword) { params.keyword = keyword; }
    getUserGroups(params)
      .then((response) => {
        if (response.data.code === 'SUCCESS') {
          if (!didCancel) {
            const newUserGroups = response.data.data.userGroups;
            setUserGroups(newUserGroups);
            const {
              page, perPage, totalItems,
            } = response.data.data.pagination;
            setPage(page);
            setPerPage(perPage);
            setTotalItems(totalItems);
          }
        } else {
          message.error(response.data.message);
        }
      })
      .catch(() => {
        message.error('获取用户群失败');
      })
      .finally(() => {
        if (!didCancel) {
          setLoading(false);
        }
      });
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page, keyword]);

  useEffect(() => {
    let didCancel = false;
    if (!loadingSelectedUserGroup) {
      // 存在选中id的情况下载入时初始化
      if (value && !userGroup && selectedRowKeys.length === 0) {
        if (!userGroups) { return; }
        setLoadingSelectedUserGroup(true);
        getUserGroup(value)
          .then((response) => {
            if (response.data.code === 'SUCCESS') {
              if (!didCancel) {
                setUserGroup(response.data.data.userGroup);
                setSelectedRowKeys([value]);
              }
            } else {
              message.error(response.data.message);
            }
          })
          .catch((error) => {
            // if (error.response.status === 404) {
            message.error('任务选用的用户群已不存在，请重新选择');
            // }
          })
          .finally(() => {
            if (!didCancel) {
              setLoadingSelectedUserGroup(false);
            }
          });
      }
    }
    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, userGroups]);

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setUserGroup(selectedRows[0]);
    },
    selectedRowKeys,
    getCheckboxProps: (record: any) => ({}),
    type: 'radio' as 'radio',
  };

  useEffect(() => {
    if (userGroup) {
      onSelect(userGroup.userGroupId);
      setSelectedRowKeys([userGroup.userGroupId]);
    }
    // onSelect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup, userGroups]);

  const name = useMemo(() => {
    if (userGroup) {
      return (
        <Tag>{userGroup.userGroupName}</Tag>
      );
    }
    if (loading || loadingSelectedUserGroup) {
      return <Loading />;
    }
    return null;
  }, [loading, loadingSelectedUserGroup, userGroup]);

  return (
    <div
      style={{
        position: 'relative',
      }}
      {...otherProps}
    >
      <div className={active ? styles.activeUserGroupArea : styles.inActiveUserGroupArea}>
        {name}
      </div>
      <Card
        hidden={!active}
        className={sideEditorStyles.sideEditor}
      >
        <h1>用户群选择</h1>
        <Search
          placeholder="请输入用户群名称/筛选规则按回车搜索"
          onSearch={(value) => setKeyword(value)}
          enterButton
        />
        <Table
          columns={columns}
          rowSelection={rowSelection}
          dataSource={userGroups}
          rowKey="userGroupId"
          loading={loading}
          scroll={{ y: window.innerHeight - 16 * 20 }}
          pagination={{
            current: page,
            pageSize: perPage,
            total: totalItems,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total: number) => `共${total}条`,
          }}
          onChange={({ current, pageSize }) => {
            setPage(current || 1);
            setPerPage(pageSize || 10);
          }}
          bodyStyle={{
            height: '34rem',
          }}
        />
      </Card>
    </div>
  );
};

export default UserGroupSelector;
