import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';

interface RequestFulfilledInterceptor {
  (value: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig>
}

interface ResponseFilfilledInterceptor {
  (value: AxiosResponse<any>): AxiosResponse<any> | Promise<AxiosResponse<any>>
}

interface RejectedInterceptor {
  (error: any): any
}

interface Interceptors {
  request: [RequestFulfilledInterceptor?, RejectedInterceptor?]
  response: [ResponseFilfilledInterceptor?, RejectedInterceptor?]
}

export function createAxiosInstance(config: AxiosRequestConfig, interceptors?: Interceptors) {
  const instance = axios.create(config);

  if (interceptors) {
    const { request, response } = interceptors;

    if (request) {
      instance.interceptors.request.use(...request);
    }

    if (response) {
      instance.interceptors.response.use(...response);
    }
  }

  return instance;
}

class AxiosClient {
  _config: AxiosRequestConfig

  _interceptors?: Interceptors

  _instance: AxiosInstance | null

  constructor(config: AxiosRequestConfig, interceptors?: Interceptors) {
    this._config = config;
    this._interceptors = interceptors;
    this._instance = null;
  }

  buildInstance() {
    const instance = createAxiosInstance(this._config, this._interceptors);

    this._instance = instance;

    return this;
  }

  config(): AxiosRequestConfig

  config(config: AxiosRequestConfig): this

  config(config?: AxiosRequestConfig): AxiosRequestConfig | this {
    if (config === undefined) {
      return this._config;
    }

    this._config = config;

    if (this._instance) {
      this.buildInstance();
    }

    return this;
  }

  interceptors(): Interceptors | undefined

  interceptors(interceptors: Interceptors): this

  interceptors(interceptors?: Interceptors): Interceptors | undefined | this {
    if (interceptors === undefined) {
      return this._interceptors;
    }

    this._interceptors = interceptors;

    if (this._instance) {
      this.buildInstance();
    }

    return this;
  }

  patchConfig(config: AxiosRequestConfig) {
    return this.config({
      ...this._config,
      ...config,
    });
  }

  patchInterceptors(interceptors: Interceptors) {
    return this.interceptors({
      ...this._interceptors,
      ...interceptors,
    });
  }

  getInstance() {
    if (this._instance === null) {
      this.buildInstance();
    }

    return this._instance as AxiosInstance;
  }

  request<T>(config: AxiosRequestConfig) {
    return this.getInstance().request<T>(config);
  }
}

export default AxiosClient;
