import React from 'react';
import Loadable from 'react-loadable';
import loadable from '@loadable/component';
import { Loading, ProtectedRoute } from 'components';
import { PlanEditorMode } from '../Plan/PlanEditor';

const getPlanEditor = (isSmsMode: boolean, editorMode: PlanEditorMode) => {
  const componentRoute = isSmsMode ? 'Plan/SmsPlanEditor' : 'Plan/PlanEditor';
  switch (editorMode) {
    case PlanEditorMode.CreatePlan: {
      return Loadable({
        loader: () => import(`../${componentRoute}`),
        loading: () => <Loading />,
        render(loaded: any, props: any) {
          const PlanEditor = loaded.default;
          return <PlanEditor {...props} mode={PlanEditorMode.CreatePlan} />;
        },
      });
    }
    case PlanEditorMode.CreateTask: {
      return Loadable({
        loader: () => import(`../${componentRoute}`),
        loading: () => <Loading />,
        render(loaded: any, props: any) {
          const PlanEditor = loaded.default;
          return <PlanEditor {...props} mode={PlanEditorMode.CreateTask} />;
        },
      });
    }
    case PlanEditorMode.EditTask: {
      return Loadable({
        loader: () => import(`../${componentRoute}`),
        loading: () => <Loading />,
        render(loaded: any, props: any) {
          const PlanEditor = loaded.default;
          return <PlanEditor {...props} mode={PlanEditorMode.EditTask} />;
        },
      });
    }
    case PlanEditorMode.CopyTask: {
      return Loadable({
        loader: () => import(`../${componentRoute}`),
        loading: () => <Loading />,
        render(loaded: any, props: any) {
          const PlanEditor = loaded.default;
          return <PlanEditor {...props} mode={PlanEditorMode.CopyTask} />;
        },
      });
    }
    default:
      return null;
  }
};

const options = {
  fallback: <Loading />,
};

const userRoutes: RouteData[] = [
  {
    path: '/group/:id/:mode?',
    exact: true,
    hidden: true,
    component: loadable(() => import('../User/UserGroupEditor'), options),
    activePath: '/group',
  },
  {
    path: '/group',
    exact: true,
    label: '用户群管理',
    key: 'user_group',
    component: loadable(() => import('../User/UserGroupManager'), options),
  },
  {
    hidden: true,
    path: '/user/:id',
    key: 'all_user',
    component: loadable(() => import('../User/UserManager'), options),
  },
  {
    path: '/user',
    exact: true,
    label: '所有用户',
    key: 'all_user',
    component: loadable(() => import('../User/UserManager'), options),
  },
];

const settingRoutes: RouteData[] = [
  {
    path: '/config/assistant/group',
    exact: true,
    label: '客服组管理',
    key: 'assistant_group',
    component: loadable(() => import('../Config/AssistantGroupManager'), options),
  },
  {
    path: '/config/blacklist',
    exact: true,
    label: '黑名单管理',
    component: loadable(() => import('../Config/BlackListManager'), options),
  },
  {
    path: '/config/send-filter',
    exact: true,
    label: '下发过滤设置',
    key: 'send_filter',
    component: loadable(() => import('../Config/SendFilter/SendFilter'), options),
  },
  {
    path: '/config/binding',
    exact: true,
    label: '账号绑定',
    component: loadable(() => import('views/Binding'), options),
  },
];

const smsSettingRoutes: RouteData[] = [
  ...settingRoutes,
  {
    path: '/config/sms',
    exact: true,
    label: '短信充值',
    key: 'sms',
    component: loadable(() => import('views/Sms'), options),
  },
  {
    path: '/config/sms/:type',
    exact: true,
    hidden: true,
    component: loadable(() => import('views/Sms'), options),
    activePath: '/config/sms',
  },
];

const devRoutes = [
  {
    icon: 'smile',
    key: 'test',
    label: '测试',
    items: [
      {
        icon: 'edit',
        key: 'test_editor',
        label: '测试编辑器',
        items: [
          {
            path: '/test/editor/:id(new)/:mode?',
            hidden: true,
            component: loadable(() => import('components/Editor'), options),
          },
          {
            path: '/test/editor/1234/copy',
            exact: true,
            label: '复制',
            activePath: '/test/editor/new(:id)/:mode?',
            component: loadable(() => import('components/Editor'), options),
          },
          {
            path: '/test/editor/1234/edit',
            exact: true,
            label: '编辑',
            activePath: '/test/editor/new(:id)/:mode?',
            component: loadable(() => import('components/Editor'), options),
          },
          {
            path: '/test/editor/new',
            exact: true,
            label: '新增',
            activePath: '/test/editor/new(:id)/:mode?',
            component: loadable(() => import('components/Editor'), options),
          },
          {
            path: '/test/editor/1234',
            label: '查看',
            activePath: '/test/editor/new(:id)/:mode?',
            component: loadable(() => import('components/Editor'), options),
          },
        ],
      },
    ],
  },
];

const getOriRoutes = (isSmsMode: boolean): RouteData[] => {
  return [
    {
      path: '/auth/reset',
      hidden: true,
      auther: true,
      component: Loadable({
        loader: () => import('../Auth/Auth'),
        loading: () => <Loading />,
        render(loaded: any, props: any) {
          const Auth = loaded.default;
          return <Auth {...props} reset />;
        },
      }),
    },
    {
      path: '/auth/:token?',
      hidden: true,
      auther: true,
      component: loadable(() => import('../Auth/Auth'), options),
    },
    {
      path: '/',
      exact: true,
      icon: 'home',
      label: '首页',
      key: 'home',
      component: loadable(() => import('../Home'), options),
    },
    {
      icon: 'user',
      label: '用户管理',
      key: 'user',
      items: userRoutes,
    },
    {
      hidden: true,
      path: '/plan/new',
      activePath: '/plans',
      exact: true,
      component: getPlanEditor(isSmsMode, PlanEditorMode.CreatePlan),
    },
    {
      hidden: true,
      path: '/plan/auto/:id',
      activePath: '/plans',
      component: Loadable({
        loader: () => import('../Plan/Plan'),
        loading: () => <Loading />,
        render(loaded: any, props: any) {
          const Plan = loaded.default;
          return <Plan {...props} readonly />;
        },
      }),
    },
    {
      hidden: true,
      path: '/plan/:id',
      exact: true,
      activePath: '/plans',
      component: loadable(() => import('../Plan/Plan'), options),
    },
    {
      hidden: true,
      path: '/plan/:planId/task/new',
      activePath: '/plans',
      component: getPlanEditor(isSmsMode, PlanEditorMode.CreateTask),
    },
    {
      hidden: true,
      path: '/plan/:planId/task/:taskId/edit',
      activePath: '/plans',
      component: getPlanEditor(isSmsMode, PlanEditorMode.EditTask),
    },
    {
      hidden: true,
      path: '/plan/:planId/task/:taskId/copy',
      activePath: '/plans',
      component: getPlanEditor(isSmsMode, PlanEditorMode.CopyTask),
    },
    {
      path: '/plans',
      icon: 'ordered-list',
      exact: true,
      label: '营销计划管理',
      key: 'plan',
      component: loadable(() => import('../Plan/PlanManager'), options),
    },
    {
      path: '/plans/auto/customer',
      activePath: '/plans',
      hidden: true,
      component: loadable(() => import('../Plan/CustomerKeeper'), options),
    },
    {
      path: '/plans/:type/:state?',
      activePath: '/plans',
      hidden: true,
      component: loadable(() => import('../Plan/PlanManager'), options),
    },
    {
      icon: 'setting',
      label: '关联配置',
      key: 'config',
      items: isSmsMode ? smsSettingRoutes : settingRoutes,
    },
  ];
};

const getRoutes = (isSmsMode: boolean) => {
  const oriRoutes = getOriRoutes(isSmsMode);
  const routes = process.env.NODE_ENV === 'development' ? [...oriRoutes, ...devRoutes] : oriRoutes;
  return routes;
};

const renderRoute = (route: RouteData, key: string): any => {
  if (route.path) {
    return (
      <ProtectedRoute
        key={key}
        auther={route.auther}
        path={route.path}
        exact={route.exact}
        component={route.component}
      />
    );
  }
  const items = route.items || [];
  return items.map((itemRoute: RouteData, itemIndex: number) =>
    renderRoute(itemRoute, `${key}_${itemIndex}`),
  );
};

const getRenderedRoutes = (isSmsMode: boolean) => {
  const routes = getRoutes(isSmsMode);
  return routes.map((route, index) => renderRoute(route, `${index}`));
};

export { getRoutes, getRenderedRoutes };
