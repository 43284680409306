import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Reducer,
} from 'react';

export const StateContext = createContext(null as any);
export const StateProvider = (
  {
    reducer,
    initialState,
    children,
  }: {
    reducer: Reducer<any, any>,
    initialState:any,
    children: ReactNode
  },
) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useGlobalState = () => useContext(StateContext);
