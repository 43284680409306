import React, { FunctionComponent, ReactElement } from 'react';
import {
  Card, Row, Col,
} from 'antd';
import LabelledNumber from '../LabelledNumber/LabelledNumber';

import styles from './PlanCard.module.scss';

interface IPlanCard {
  title: string | ReactElement,
  totalReach: number,
  totalReply: number,
  totalPayment: number
}

const PlanCard: FunctionComponent<IPlanCard> = (props: IPlanCard) => {
  const {
    title,
    totalReach,
    totalReply,
    totalPayment,
  } = props;
  return (
    <Card
      style={{
        margin: '.5rem auto',
      }}
    >
      <Row>
        <h3 className={styles.title}>
          {title}
        </h3>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <LabelledNumber
            label="送达人数"
            small
            value={totalReach}
            help="营销计划发送人数"
          />
        </Col>
        <Col span={8}>
          <LabelledNumber
            label="回复人数"
            small
            value={totalReply}
            help="营销信息发出后，归因时间内联系过店铺客服的买家累计"
          />
        </Col>
        <Col span={8}>
          <LabelledNumber
            label="付款金额"
            rmb
            small
            value={totalPayment}
            help="营销信息发出后，归因时间内买家付款金额累计"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default PlanCard;
