import React, { ReactElement, FC } from 'react';
import { Row, Col, Statistic, Tooltip, Icon } from 'antd';
import CashSvg from 'assets/images/cash.svg';
import MarkSvg from 'assets/images/mark.svg';
import { useGlobalState } from 'hooks/useGlobalState';
import LabelledNumber from '../LabelledNumber/LabelledNumber';
import styles from './PlanCardNew.module.scss';

interface IPlanCardNew {
  title: string | ReactElement;
  totalReach: number;
  totalReply: number;
  totalPayment: number;
  smsCoverNumber: number;
  tbCoverNumber: number;
  sumReplyCount: number;
  type: string;
}

const PlanCardNew: FC<IPlanCardNew> = (props: IPlanCardNew) => {
  const {
    title,
    totalReach,
    totalReply,
    totalPayment,
    smsCoverNumber,
    tbCoverNumber,
    sumReplyCount,
    type,
  } = props;
  const [globalState] = useGlobalState();
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {title}
        <img src={MarkSvg} alt="" className={styles.titleImage} />
      </h3>
      {type === 'custom' ? (
        <Row type="flex" justify="center" align="middle" gutter={24} className={styles.body}>
          <Col span={12} className={styles.customModeContent}>
            <div className={styles.customModeLeftContent}>
              <img src={CashSvg} alt="" className={styles.leftPicture} />
              {totalPayment > 1000000 ? (
                <div>
                  <div className={styles.leftTextTitle}>付款总金额</div>
                  <div className={styles.leftTextNumberContainer}>
                    <Statistic
                      valueStyle={{ color: '#515359', fontSize: '16px' }}
                      value={`￥ ${Math.floor(totalPayment / 10000) / 100}万`}
                    />
                    <Tooltip
                      placement="right"
                      title={
                        <Statistic
                          valueStyle={{ fontSize: '15px', color: '#515359' }}
                          value={totalPayment / 100}
                          precision={2}
                          suffix="元"
                        />
                      }
                    >
                      <Icon type="question-circle" style={{ margin: '0 .25rem' }} />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <LabelledNumber
                  style={{ minWidth: '42px' }}
                  label="付款总金额"
                  rmb
                  small
                  labelStyle={{ fontSize: '12px', color: '#84868C' }}
                  valueStyle={{ fontSize: '16px', color: '#515359' }}
                  value={totalPayment}
                />
              )}
            </div>
          </Col>
          <Col span={12} className={styles.leftUlContainer}>
            <ul className={styles.planUl}>
              <li className={styles.planLiText}>
                短信触达人数:
                {totalPayment !== -1 ? (
                  <Statistic
                    valueStyle={{ fontSize: '14px', color: '#84868C' }}
                    style={{ display: 'inline-block' }}
                    value={smsCoverNumber}
                  />
                ) : (
                  '-'
                )}
              </li>
              {globalState.shopInfo.dxVersion === 'smsOnly' ? null : (
                <>
                  <li className={styles.planLiText}>
                    淘内触达人数:
                    {totalReach !== -1 ? (
                      <Statistic
                        valueStyle={{ fontSize: '14px', color: '#84868C' }}
                        style={{ display: 'inline-block' }}
                        value={tbCoverNumber}
                      />
                    ) : (
                      '-'
                    )}
                  </li>
                  <li className={styles.planLiText} style={{ marginBottom: 0 }}>
                    淘内回复人数:
                    {totalReply !== -1 ? (
                      <Statistic
                        valueStyle={{ fontSize: '14px', color: '#84868C' }}
                        style={{ display: 'inline-block' }}
                        value={sumReplyCount}
                      />
                    ) : (
                      '-'
                    )}
                  </li>
                </>
              )}
            </ul>
          </Col>
        </Row>
      ) : (
        <Row gutter={24} className={styles.autoModeContent}>
          <Col span={8} className={styles.autoItem}>
            <LabelledNumber
              label="送达人数"
              labelStyle={{ fontSize: '12px', color: '#84868C' }}
              valueStyle={{ fontSize: '18px', color: '#515359' }}
              small
              value={totalReach}
            />
          </Col>
          <Col span={8} className={styles.autoItem}>
            <LabelledNumber
              label="回复人数"
              labelStyle={{ fontSize: '12px', color: '#84868C' }}
              valueStyle={{ fontSize: '18px', color: '#515359' }}
              small
              value={totalReply}
            />
          </Col>
          <Col span={8} style={{ padding: 0 }}>
            <LabelledNumber
              label="付款金额"
              labelStyle={{ fontSize: '12px', color: '#84868C' }}
              valueStyle={{ fontSize: '18px', color: '#515359' }}
              rmb
              small
              value={totalPayment}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PlanCardNew;
