export const GET_SHOP_INFO = 'GET_SHOP_INFO';
export const UPDATE_SHOP_INFO = 'UPDATE_SHOP_INFO';
export const INIT_SHOP_INFO = 'INIT_SHOP_INFO';

export interface ShopInfo {
  storeId: string;
  storeName: string;
  customerId: string;
  smsFunction: boolean;
  dxVersion: string;
}

interface ShopInfoState extends ShopInfo {
  pending: boolean;
}

export const initialShopInfo: { shopInfo: ShopInfoState } = {
  shopInfo: {
    pending: false,
    storeId: '',
    storeName: '',
    customerId: '',
    smsFunction: true,
    dxVersion: 'tbAndSms',
  },
};

export const shopInfoReducer: IReducer = (state, action) => {
  switch (action.type) {
    case GET_SHOP_INFO:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_SHOP_INFO:
      return {
        ...action.payload,
        pending: false,
      };
    case INIT_SHOP_INFO:
      return {
        ...initialShopInfo,
      };
    default:
      return state;
  }
};
