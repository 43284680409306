export const UPDATE_ASSISTANT_GROUP = 'UPDATE_ASSISTANT_GROUP';
// export const WRITE_ASSISTANT_GROUP = 'WRITE_ASSISTANT_GROUP'
export const RESET_ASSISTANT_GROUP = 'RESET_ASSISTANT_GROUP';
// export const FETCH_ASSISTANT_GROUPS = 'FETCH_ASSISTANT_GROUPS'
// export const FETCH_ASSISTANT_GROUP = 'FETCH_ASSISTANT_GROUP'

export const initialAssistantGroupState = {
  assistantGroups: [],
};

export const assistantGroupReducer: IReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ASSISTANT_GROUP:
      if (!action.payload) { return state; }
      return [...action.payload];
    case RESET_ASSISTANT_GROUP:
      return state;
    default:
      return state;
  }
};
