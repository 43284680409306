import React, { FC } from 'react';
import { Spin } from 'antd';

const baseStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const inlineStyle = {
  display: 'inline',
  padding: '.25rem',
};

interface LoadingProps {
  inline?: boolean,
}

const Loading: FC<LoadingProps> = ({ inline = false }) => (
  <div
    style={
      inline
        ? {
          ...baseStyle,
          ...inlineStyle,
        }
        : baseStyle
    }
  >
    <Spin
      size={
        inline
          ? 'small'
          : 'default'
      }
    />
  </div>
);

export default Loading;
