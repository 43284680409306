import React, { FunctionComponent, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { READ_AUTH_TOKEN } from '../reducer/auth';
import { useGlobalState } from '../hooks/useGlobalState';
import Loading from './Loading';

interface IProtectedRoute {
  [x: string]: any
}
const ProtectedRoute: FunctionComponent<IProtectedRoute> = ({
  component, auther, path, exact, key, ...otherProps
}) => {
  const [{ auth }, dispatch] = useGlobalState();
  useEffect(() => dispatch(READ_AUTH_TOKEN), [dispatch]);
  const Component = component;
  return (
    <Route
      key={key}
      path={path}
      exact={exact}
      render={({ match, history }) => {
        if (auther) {
          return (
            <Component
              {...otherProps}
              match={match}
              history={history}
            />
          );
        }
        if (auth && auth.token) {
          return (
            <Component
              {...otherProps}
              match={match}
              history={history}
            />
          );
        }
        if (auth) {
          return <Redirect to="/auth" />;
        }
        return <Loading />;
      }}
    />
  );
};

export default ProtectedRoute;
