import { notification } from 'antd';
import Axios, { AxiosRequestConfig } from 'axios';
import errorResponseHandler from 'utils/handleApiError';

// TODO: 统一抽取所有页面内数据请求的操作

const lxxClient = Axios.create({
  baseURL: process.env.REACT_APP_LXX_API,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
});
lxxClient.interceptors.response.use((response) => {
  if (response.data.code === 'DATA_MIGRATION_FILTER') {
    notification.error({
      message: '系统维护通知',
      description: response.data.message,
    });
  }
  return response;
}, errorResponseHandler);

const lxkClient = Axios.create({
  baseURL: process.env.REACT_APP_LXK_API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
});

lxkClient.interceptors.response.use(
  (response) => response,
  () => {
    return Promise.reject();
  },
);

const itemClient = Axios.create({
  baseURL: process.env.REACT_APP_ITEM_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
});

const ERROR_CODE = {
  SUCCESS: 'SUCCESS',
};
function handleResponse(res: {
  data: {
    code: string;
    message?: string;
    data: any;
  };
}) {
  const success = res.data.code;
  if (success === ERROR_CODE.SUCCESS) {
    return res.data;
  }
  const error = new Error(res.data.message);
  return Promise.reject(error);
}

function getShopInfo() {
  return lxxClient.get('store/info');
}

/**
 * 发送新计划任务
 */
function createPlan(plan: IPlanNew) {
  return lxxClient.post('marketing/plan', plan);
}
function createTask(planId: string, task: IPlanTaskNew) {
  return lxxClient.post(`marketing/plan/${planId}/task`, task);
}
function updateTask(taskId: string, task: IPlanTaskNew) {
  return lxxClient.patch(`marketing/task/${taskId}`, task);
}
function deleteTask(planId: string, taskId: string) {
  return lxxClient.delete(`marketing/plan/${planId}/task/${taskId}`);
}
/**
 * 开关任务
 */
function togglePlan(id: string, enable: boolean) {
  return lxxClient.put(`marketing/plan/${id}/enable?enable=${enable}`);
}

function getPlan(id: string) {
  return lxxClient.get(`marketing/plan/${id}`);
}
function updatePlan(id: string, planName: string) {
  const planData = {
    planName,
  };
  return lxxClient.patch(`marketing/plan/${id}`, planData);
}
/**
 * 营销计划成果分析，营销任务对比分析接口
 * @param id plan_id
 */
function getPlanReport(id: string) {
  return lxxClient.get(`/report/plan/${id}/summary`);
}

/**
 * 营销模块，产看明细table接口
 * @param planId
 * @param page 当前页
 * @param perPage 每页条数
 */
async function getPlanReportTrade(
  planId: string,
  page: number,
  perPage: number,
  hasReply?: boolean,
  hasTrade?: boolean,
) {
  let requestString = `/report/plan/${planId}/detail?planId=${planId}&page=${page}&perPage=${perPage}`;
  requestString += '&hasReply=';
  if (hasReply !== undefined) {
    requestString += `${hasReply}`;
  }
  requestString += '&hasTrade=';
  if (hasTrade !== undefined) {
    requestString += `${hasTrade}`;
  }
  const response = await lxxClient.get(requestString);
  return response;
}
function getUsers(params = {}) {
  return lxxClient.get('users', { params });
}
function getUsersByScroll(params = {}) {
  return lxxClient.get('usersByScroll', { params });
}
function usersNum() {
  return lxxClient.get('usersNum');
}
function getUserGroups(params = {}) {
  return lxxClient.get('userGroups', { params });
}
function getUserGroup(groupId: number) {
  return lxxClient.get(`userGroups/${groupId}`);
}
function checkUserPhone(params = {}) {
  return lxxClient.get('import/check', { params });
}
function customizeUser(mobile: string, buyerNick: string) {
  return lxxClient.post('import/save', { mobile, buyerNick });
}
function createUserGroup(group: IUserGroupNew) {
  return lxxClient.post('userGroup', group);
}
function updateUserGroup(groupId: number, group: IUserGroupNew) {
  return lxxClient.put(`userGroup/${groupId}`, group);
}
function deleteUserGroup(groupId: number) {
  return lxxClient.delete(`userGroup/${groupId}`);
}
function deleteAssistantGroup(groupId: string) {
  return lxxClient.delete(`assistantGroups/${groupId}`);
}
function getAssistantGroups(params = {}) {
  return lxxClient.get('assistantGroups', { params });
}
// function generateShortLink(link:string){

// }
function createAssistantGroups(name: string, assistantList: Array<string>) {
  return lxxClient.post('assistantGroups', {
    name,
    assistantList,
  });
}
function updateAssistantGroups(id: string, name: string, assistantList: Array<string>) {
  return lxxClient.put(`assistantGroups/${id}`, {
    name,
    assistantList,
  });
}
function getCustomPlans(params = {}) {
  return lxxClient.get('marketing/plans', { params: { ...params, type: 'MANUAL' } });
}
function getAutoPlans(params = {}) {
  return lxxClient.get('marketing/plans', { params: { ...params, type: 'AUTO' } });
}
function getRuleTemplate() {
  return lxxClient.get('userGroup/rule/template');
}
function getReportAmounts() {
  return lxxClient.get('report/amounts');
}
function getProvinces() {
  return lxxClient.get('address');
}
function getProvinceCitiesById(id: string) {
  return lxxClient.get(`address/${id}`);
}
export const defaultExtraConditions = {
  orderInfo: {},
  baseInfo: {},
  behaviorInfo: {},
};
function countConditions(
  userGroupMetaJson: {
    conditions: TConditionGroup;
    exclusiveConditions?: TConditionGroup;
    operator: number;
  },
  filterConditionGroupMetaJson: {
    conditions?: TConditionGroup;
  } = {},
  ruleType: number = 0,
  userGroupId: number = 0,
  config?: AxiosRequestConfig,
) {
  return lxxClient.post(
    'count/conditions',
    {
      userGroupMetaJson: JSON.stringify(userGroupMetaJson),
      filterConditionGroupMetaJson: !Object.keys(filterConditionGroupMetaJson).length
        ? ''
        : JSON.stringify(filterConditionGroupMetaJson),
      ruleType,
      userGroupId,
    },
    {
      ...config,
    },
  );
}
/**
 * 获取自动营销配置
 */
function getAutoConfig() {
  return lxxClient.get('autoconfig/retrieve');
}
/**
 * 更新自动营销配置
 */
function updateAutoConfig(data = {}) {
  return lxxClient.post('autoconfig/retrieve', data);
}
/**
 * 停止计划中的一个任务
 * @param planId
 * @param taskId
 */
function cancelPlanTask(planId: string, taskId: string) {
  return lxxClient.delete(`marketing/plan/${planId}/task/${taskId}/cancel`);
}
/**
 * 黑名单相关
 */
function getBlackList(params = {}) {
  return lxxClient
    .get('blacklist', { params })
    .then(handleResponse)
    .then(({ data }) => data);
}
function addBlackList(nameList: Array<string> = []) {
  return lxxClient
    .post('blacklist', nameList)
    .then(handleResponse)
    .then(({ code }) => code);
}
function deleteBlackList(id: number) {
  return lxxClient
    .delete(`blacklist/${id}`)
    .then(handleResponse)
    .then(({ code }) => code);
}

function getInventories(params: any = {}) {
  return lxxClient
    .get('turnstile/inventories', {
      params,
    })
    .then(handleResponse)
    .then(({ data }) => data);
}
/**
 * 短信任务相关
 */
function testSms(phoneNumber: string, sendContent: string) {
  return lxxClient.post('sms/testSms', { phoneNumber, sendContent });
}
function getSmsTemplates() {
  return lxxClient.get('marketing/plan/sms/templates');
}
function getShortLink(url: string) {
  return lxxClient.post('marketing/plan/short-link', { url });
}
/**
 * 短信充值相关
 */
function createRechargeOrder(params = {}) {
  return lxxClient.post('trade/recharge', params);
}
function getUserBalance() {
  return lxxClient.get('trade/balance');
}
function getRechargeRecords(params = {}) {
  return lxxClient.get('trade/records', { params });
}
function getOrderStatus(tradeNo: number | string) {
  return lxxClient.get(`trade/record/${tradeNo}`);
}

const defaultQueryBody = {
  preorder: 'NOTSET',
  cids: [],
  invalid_suggestion: 'NOTSET',
  page: 1,
  order_field: 'PUTAWAY',
  has_concept_ids: [],
  suggestion_ids: [],
  keyword: '',
  empty_concept_ids: [],
  shop_cids: [],
  per_page: 10,
  spu_ids: [],
  created: 'NO_FILTER',
  order_by: 'DESC',
};

function fetchItemList(body = {}) {
  return itemClient.post(
    'items/search',
    {
      ...defaultQueryBody,
      ...body,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

export type Created = 'NO_FILTER' | 'TODAY' | 'YESTERDAY' | 'WEEK' | 'MONTH' | 'IN_STOCK';
export type OrderBy = 'DESC' | 'ASC';
export type OrderField = 'PUTAWAY' | 'CREATED' | 'HOT';
export type SetState = 'NOTSET' | 'TRUE' | 'FALSE';
export type Source = 'SKU_INFO';

export interface Category {
  cid: string;
  name: string;
}

export interface Concept {
  id: string;
  name: string;
  value: string;
}

export interface Suggestion {
  id: string;
  name: string;
  attention: boolean;
}

export interface Sku {
  id: string;
  name: string;
}

export interface Item {
  // store_id: string
  // eslint-disable-next-line camelcase
  spu_id: string;
  title: string;
  url: string;
  preorder: boolean;
  onsale: boolean;
  created: string;
  // eslint-disable-next-line camelcase
  picture_url: string;
  price: 0;
  // outer_id: string
  hot: string;
  category: Category;
  // shop_categories: Array<Category>
  concepts: Array<Concept>;
  suggestion: Suggestion;
  skus: Array<Sku>;
}

export interface ListItemResponse {
  code: number;
  msg: string;
  items: Array<Item>;
  // last_update_time: string
  total: string;
}

export {
  lxxClient,
  getShopInfo,
  getPlan,
  createPlan,
  updatePlan,
  createTask,
  updateTask,
  deleteTask,
  togglePlan,
  getUsers,
  getUsersByScroll,
  usersNum,
  getUserGroups,
  getUserGroup,
  createUserGroup,
  updateUserGroup,
  deleteUserGroup,
  deleteAssistantGroup,
  getAssistantGroups,
  createAssistantGroups,
  updateAssistantGroups,
  getCustomPlans,
  getAutoPlans,
  getRuleTemplate,
  getReportAmounts,
  getPlanReport,
  getPlanReportTrade,
  getProvinces,
  getProvinceCitiesById,
  countConditions,
  getAutoConfig,
  updateAutoConfig,
  cancelPlanTask,
  getBlackList,
  addBlackList,
  deleteBlackList,
  getInventories,
  checkUserPhone,
  customizeUser,
  createRechargeOrder,
  getUserBalance,
  getRechargeRecords,
  getOrderStatus,
  testSms,
  getSmsTemplates,
  getShortLink,
  fetchItemList,
};
