import React, { FunctionComponent, CSSProperties, memo } from 'react';
import { Tag, Tooltip } from 'antd';

interface IConditionTag {
  type?: 'and' | 'or' | 'not',
  [x: string]: any
}

const sharedTagStyle: CSSProperties = {
  margin: '.25rem',
};

const contentStyle: CSSProperties = {
  overflow: 'hidden',
  maxWidth: '18rem',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  height: '21px',
  verticalAlign: 'middle',
};

const ConditionTag: FunctionComponent<IConditionTag> = ({ children, type, ...otherProps }) => {
  let style;
  switch (type) {
    case 'not':
      style = {
        ...sharedTagStyle, borderColor: '#c6c6c6', backgroundColor: '#EFF0F2', color: '#6C7293',
      };
      break;
    case 'or':
      style = {
        ...sharedTagStyle, borderColor: '#BEC8FB', backgroundColor: '#EAEEFF', color: '#5D78FF',
      };
      break;
    case 'and':
      style = {
        ...sharedTagStyle, borderColor: '#91D5FF', backgroundColor: '#E6F7FF', color: '#1890FF',
      };
      break;
    default:
      style = sharedTagStyle;
  }
  return (
    <Tooltip
      title={children}
    >
      <Tag
        style={style}
        {...otherProps}
      >
        <div style={contentStyle}>
          {children}
        </div>
      </Tag>
    </Tooltip>
  );
};

export default memo(ConditionTag);
