import React, { FunctionComponent } from 'react';
import generateTags from 'utils/generateTags';
import ConditionTag from './ConditionTag';
import { tagToName } from '../config/autoPlan';

const TaskTagGroup:FunctionComponent<{task: IPlanTask}> = ({ task }) => {
  const userGroupName = task.userGroup ? task.userGroup.userGroupName : tagToName(task.userGroupTag || '');
  const { ruleType } = task;
  const generatedConditions = generateTags(task.filter && task.filter.conditions
    ? task.filter.conditions : {});
  return (
    <>
      {
        userGroupName ? (
          <ConditionTag
            type="and"
            key={userGroupName}
          >
            {userGroupName}
          </ConditionTag>
        ) : null
      }
      {
        generatedConditions.map((condition) => {
          return (
            <ConditionTag
              type={ruleType === 1 ? 'and' : 'not'}
              key={condition.name}
            >
              {condition.shortRuleName}
            </ConditionTag>
          );
        })
      }
    </>
  );
};
export default TaskTagGroup;
