// 从condition对象的shortRuleNames生成Tag列表
const generateTags = (conditions: TConditionGroup) => {
  const notNullConditions = conditions || {};
  const allConditions: Array<{
    name: string,
    // detail: TCondition,
    shortRuleName: string
  }> = [];
  Object.keys(notNullConditions).forEach((key: string) => {
    const conditionGroup = notNullConditions[key];
    if (conditionGroup) {
      Object.keys(conditionGroup).forEach((key: string) => {
        const condition = conditionGroup[key];
        if (condition && (condition.enable === undefined || condition.enable === true)) {
          if (condition.shortRuleName) {
            allConditions.push({ name: key, shortRuleName: condition.shortRuleName });
          }
        }
      });
    }
  });
  return allConditions;
};

export default generateTags;
