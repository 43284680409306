import { Executor, Flag, FlagProps } from '@leyan/flag-runner';
import { get } from 'lodash-es';

const FLAGR_ENDPOINT = process.env.REACT_APP_FLAGR_ENDPOINT as string;
const excuter = new Executor({
  endpoint: FLAGR_ENDPOINT,
  batchMode: true,
});

interface FeatureProps extends FlagProps {
  name: string;
  parse?: (flagResult: any) => any;
}
const createFeature = (
  featureName: string,
  flagKey: string,
  entityContext: { [x: string]: any },
  options?: Partial<Omit<FeatureProps, 'name' | 'key' | 'entityContext' | 'createContext'>>,
): FeatureProps => {
  const { schema = {}, ...otherOpts } = options || {};
  return {
    name: featureName,
    key: flagKey,
    schema,
    createContext: () => ({ entityContext }),
    ...otherOpts,
  };
};

const flags: FeatureProps[] = [
  createFeature(
    'demotionTime',
    'k333zaogv95vervaq',
    {},
    { parse: (raw: Flag) => get(raw, 'attachment') || {} },
  ),
];

const getFlagrData = async () => {
  const result = await excuter.execute(flags.map((f) => new Flag(f))).catch(() => {
    return [] as Flag[];
  });
  const features = result.reduce((map, item, idx) => {
    const flag = flags[idx];
    if (flag?.name) {
      // eslint-disable-next-line no-param-reassign
      map[flag.name] = flag?.parse ? flag!.parse!(item) : item;
    }
    return map;
  }, {} as { [x: string]: any });
  return features;
};

export default getFlagrData;
