import React, {
  FunctionComponent, useMemo, ReactElement,
} from 'react';
import { escapeRegExp } from 'lodash';

interface IKeyword {
  full: string,
  keyword: string,
  caseSensitive?: boolean
}

const Keyword: FunctionComponent<IKeyword> = ({
  full, keyword, caseSensitive = false,
}) => {
  const foundIndexes = useMemo(() => {
    if (!keyword) { return []; }
    const reg = new RegExp(escapeRegExp(keyword), caseSensitive ? 'g' : 'gi');
    const indexes = [];
    let matches: RegExpExecArray | null = null;
    // eslint-disable-next-line no-cond-assign
    while ((matches = reg.exec(full))) {
      indexes.push(matches.index);
    }
    return indexes;
  }, [full, keyword, caseSensitive]);
  if (!foundIndexes.length) {
    return <>{full}</>;
  }

  const slots: ReactElement[] = [];
  if (foundIndexes[0] > 0) {
    slots.push(
      <span key="head">
        {full.slice(0, foundIndexes[0])}
      </span>,
    );
  }
  foundIndexes.forEach((index, pos) => {
    slots.push(
      <span
        key={index}
        style={{ background: 'yellow' }}
      >
        {full.slice(index, index + keyword.length)}
      </span>,
    );
    const normalIndex = index + keyword.length;
    const nextIndex = foundIndexes[pos + 1];
    if (!Number.isNaN(nextIndex) && normalIndex < nextIndex) {
      slots.push(
        <span
          key={normalIndex}
        >
          {full.slice(normalIndex, nextIndex)}
        </span>,
      );
    }
  });
  if (foundIndexes[foundIndexes.length - 1] < full.length - 1) {
    slots.push(
      <span
        key="tail"
      >
        {full.slice(foundIndexes[foundIndexes.length - 1] + keyword.length)}
      </span>,
    );
  }

  return (
    <>
      {
        slots.map((slot) => slot)
      }
    </>
  );
};

export default Keyword;
