import React, { FC, useMemo } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import translation from 'config/translation';
import PlanCard from '../PlanCard';
import PlanCardNew from '../PlanCardNew';
import styles from './PlanCardShortList.module.scss';

interface IPlanCardShortList {
  isShortMessageMode: boolean
  plans: Array<any>,
  type: 'custom' | 'auto'
}

const PlanCardShortList: FC<IPlanCardShortList> = ({
  isShortMessageMode, plans, type,
}) => {
  const planCards = useMemo(() => {
    if (isShortMessageMode) {
      return plans.map((plan: any) => (
        <Col
          span={24}
          lg={12}
          xl={8}
          xxl={6}
          key={plan.planId}
          className={styles.planCardContainer}
        >
          <PlanCardNew
            type={type}
            title={(
              <Link
                title={plan.planName}
                to={`/plan/${plan.planId}`}
              >
                {plan.planName}
              </Link>
            )}
            totalReach={plan.personsServedNumber}
            totalReply={plan.sumReplyCount}
            totalPayment={plan.sumPaidAmountCent}
            smsCoverNumber={plan.smsCoverNumber}
            tbCoverNumber={plan.tbCoverNumber}
            sumReplyCount={plan.sumReplyCount}
          />
        </Col>
      ));
    }
    return plans.map((plan: any) => (
      <Col
        span={24}
        lg={12}
        xl={8}
        xxl={6}
        key={plan.planId}
        className={styles.planCardContainer}
      >
        <PlanCard
          title={(
            <Link
              title={plan.planName}
              to={`/plan/${plan.planId}`}
            >
              {plan.planName}
            </Link>
            )}
          totalReach={plan.personsServedNumber}
          totalReply={plan.sumReplyCount}
          totalPayment={plan.sumPaidAmountCent}
        />
      </Col>
    ));
  }, [isShortMessageMode, plans, type]);
  return (
    <div className={styles.mb12}>
      <Row
        gutter={24}
        type="flex"
        justify="end"
        className={styles.mb12}
      >
        <Col
          span={24}
          style={{ textAlign: 'right' }}
        >
          <Link
            to={`/plans/${type}`}
            className={styles.toList}
          >
            <span>查看全部</span>
            <span>{type === 'auto' ? translation.plan.auto.title : translation.plan.custom.title}</span>
            <span>计划&gt;</span>
          </Link>
        </Col>
      </Row>
      <Row gutter={[16, 8]} className={styles.planCardListContainer}>
        {
          planCards
        }
      </Row>
    </div>
  );
};

export default PlanCardShortList;
