import React, { useMemo, memo } from 'react';
import { Icon } from 'antd';

const ErrorIcon = () => {
  const style = useMemo(() => (
    { color: 'red', margin: '0 .25rem 0 0' }
  ), []);
  return (
    <Icon
      type="exclamation-circle"
      theme="filled"
      style={style}
    />
  );
};

export default memo(ErrorIcon);
