import React, { FunctionComponent } from 'react';
import { Statistic } from 'antd';
import styles from './StatisticInLine.module.scss';

interface IStatisticInLine {
  title?: string,
  value: number,
  unit: string,
  style?: React.CSSProperties,
}

const StatisticInLine:FunctionComponent<IStatisticInLine> = (props) => {
  const {
    title = '',
    unit,
    value,
    style = {},
  } = props;

  return (
    value === -1 ? (
      <>-</>
    ) : (
      <Statistic
        style={{ maxWidth: 'max-content', ...style }}
        valueStyle={{ fontSize: '16px', color: '#178FFE' }}
        prefix={<span className={styles.cardCountTitle}>{title}</span>}
        suffix={<span className={styles.cardCountSuffix}>{unit}</span>}
        value={value}
      />
    )
  );
};

export default StatisticInLine;
