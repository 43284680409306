import React, { FunctionComponent } from 'react';
import { Popover, Icon } from 'antd';
import translation from 'config/translation';
import ConditionTag from '../ConditionTag';

import styles from './RulePopIcon.module.scss';

const RulePopIcon: FunctionComponent<any> = () => {
  return (
    <Popover
      title={null}
      content={(
        <div>
          <div className={styles.title}>{`${translation.user.group.coverRule}标签颜色含义`}</div>
          <div>
            <ConditionTag
              type="or"
            >
              规则1
            </ConditionTag>
            <ConditionTag
              type="or"
            >
              规则2
            </ConditionTag>
            <span className={styles.desc}>用户群满足【规则1】或【规则2】</span>
          </div>
          <div>
            <ConditionTag
              type="and"
            >
              规则1
            </ConditionTag>
            <ConditionTag
              type="and"
            >
              规则2
            </ConditionTag>
            <span className={styles.desc}>用户群满足【规则1】且【规则2】</span>
          </div>
          <div>
            <ConditionTag
              type="and"
            >
              规则1
            </ConditionTag>
            <ConditionTag
              type="not"
            >
              规则2
            </ConditionTag>
            <span className={styles.desc}>用户群满足【规则1】排除【规则2】</span>
          </div>
        </div>
      )}
    >
      <Icon type="question-circle" style={{ margin: '0 .25rem' }} />
    </Popover>
  );
};

export default RulePopIcon;
