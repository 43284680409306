const losingConsumer = {
  tag: 'TAG_LOST_CONSUMER',
  name: '流失边缘用户',
};

const autoPlan = {
  losingConsumer,
  groups: [
    losingConsumer,
  ],
};

const tagToName = (tag: string): string => {
  const found = autoPlan.groups.find((plan) => plan.tag === tag);
  if (found) {
    return found.name;
  }
  return '[tag]';
};

export default autoPlan;
export {
  tagToName,
};
