import React, { FunctionComponent, useMemo } from 'react';
import { Menu, Icon } from 'antd';
import { Link, RouteComponentProps } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';

const { SubMenu } = Menu;

interface IMenuUseRouter extends RouteComponentProps<any> {
  routes: Array<RouteData>
}

const renderMenuRoute = (route: RouteData) => {
  if (route.hidden) {
    return null;
  }
  if (route.path) {
    return (
      <Menu.Item key={route.path}>
        <Link to={route.path}>
          {
            route.icon
              ? (<Icon type={route.icon} />)
              : null
          }
          <span>{route.label}</span>
        </Link>
      </Menu.Item>
    );
  }
  const items = route.items || [];
  return (
    <SubMenu
      key={route.key}
      title={(
        <span>
          <Icon type={route.icon} />
          <span>{route.label}</span>
        </span>
      )}
    >
      {items.map((itemRoute: RouteData) => renderMenuRoute(itemRoute))}
    </SubMenu>
  );
};

const cacheActivePath = (
  routes:RouteData[],
  pathname: string,
  parentKeys: string[] = [],
) => {
  const selectedKeys:string[] = [];
  const defaultOpenKeys:string[] = [];
  routes.forEach((route) => {
    if (route.path) {
      const regexp = pathToRegexp(route.path);
      if (regexp.test(pathname)) {
        selectedKeys.push(route.activePath ? route.activePath : route.path);
        defaultOpenKeys.push(...parentKeys);
      }
    } else if (Array.isArray(route.items) && route.items.length) {
      if (route.key) {
        parentKeys.push(route.key);
      }
      const [
        subRouteSelectedKeys,
        subRouteDefaultOpenKeys,
      ] = cacheActivePath(route.items, pathname, parentKeys);
      selectedKeys.push(...subRouteSelectedKeys);
      defaultOpenKeys.push(...subRouteDefaultOpenKeys);
    }
  });
  return [selectedKeys, defaultOpenKeys];
};

const MenuUseRouter:FunctionComponent<IMenuUseRouter> = ({
  routes, location,
}) => {
  const [selectedKeys, defaultOpenKeys] = useMemo(() => {
    return cacheActivePath(routes, location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultOpenKeys={defaultOpenKeys}
      selectedKeys={selectedKeys}
    >
      {routes.map((route) => renderMenuRoute(route))}
    </Menu>
  );
};

export default MenuUseRouter;
