import React, { useEffect, FunctionComponent, useMemo, useCallback } from 'react';
import { Layout, message, Modal, Spin } from 'antd';
import * as jwt from 'jsonwebtoken';
import {
  HashRouter as Router,
  Switch,
  Redirect,
  Route,
  RouteComponentProps,
} from 'react-router-dom';
import { MenuUseRouter } from 'components';
import MenuHeader from 'components/MenuHeader';
import { useGlobalState } from 'hooks/useGlobalState';
import { READ_AUTH_TOKEN } from 'reducer/auth';
import { GET_SHOP_INFO, INIT_SHOP_INFO, UPDATE_SHOP_INFO } from 'reducer/shopInfo';
import { getShopInfo } from 'api/lxxApi';
import moment from 'moment';
import getFlagrData from 'api/flagr';
import { useRequest, useToggle } from 'ahooks';
import { getRoutes, getRenderedRoutes } from './routes';
import 'antd/dist/antd.css';
import styles from './App.module.scss';

export const initialMaintainInfo = {
  start: undefined,
  end: undefined,
  isFirstIn: true,
};

const { Header, Sider } = Layout;

const App: FunctionComponent = () => {
  const [globalState, dispatch] = useGlobalState();
  const {
    auth,
    shopInfo: { pending, smsFunction: isSmsMode },
  } = globalState;

  const {
    data: flagrInfo = initialMaintainInfo,
    loading: flagrLoading,
    mutate: setFlagrData,
  } = useRequest(async () => {
    try {
      const { demotionTime } = await getFlagrData();
      return {
        ...initialMaintainInfo,
        ...demotionTime,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('获取flagr数据失败');
      return initialMaintainInfo;
    }
  });

  const [visible, { setLeft: close }] = useToggle();

  const isShow1111 = useMemo(() => {
    const now = moment();
    if (now.isAfter(flagrInfo.start) && now.isBefore(flagrInfo.end)) {
      return true;
    }
    return false;
  }, [flagrInfo]);

  const handleModalClose = useCallback(() => {
    if (flagrInfo.isFirstIn) {
      setFlagrData({ ...flagrInfo, isFirstIn: false });
    }
    close();
  }, [close, flagrInfo, setFlagrData]);
  useEffect(() => dispatch({ type: READ_AUTH_TOKEN }), [dispatch]);
  useEffect(() => {
    if (auth && auth.token) {
      dispatch({ type: GET_SHOP_INFO });
      getShopInfo()
        .then((response) => {
          const {
            data: { data: shopInfo },
          } = response;
          dispatch({ type: UPDATE_SHOP_INFO, payload: shopInfo });
        })
        .catch(() => {
          dispatch({ type: INIT_SHOP_INFO });
        });
    }
  }, [auth, dispatch]);

  const shopTitle = useMemo(() => {
    let storeName = '';
    if (auth && auth.token) {
      try {
        const info = jwt.decode(auth.token) as {
          [key: string]: any;
          // eslint-disable-next-line camelcase
          store_name?: string;
        };
        if (info && info.store_name) {
          storeName = info.store_name;
        }
      } catch (e) {
        message.error('授权信息非法');
        window.location.href = '/#/auth/reset';
        window.location.reload();
      }
    }
    return storeName;
  }, [auth]);

  const renderRouter = useMemo(() => getRenderedRoutes(isSmsMode), [isSmsMode]);

  return (
    <Router>
      <Spin spinning={pending || flagrLoading}>
        <Layout>
          {auth?.token ? (
            <Sider trigger={null} className={styles.sider}>
              <MenuHeader title={shopTitle} />
              <Route
                render={(props: RouteComponentProps) => (
                  <MenuUseRouter {...props} routes={getRoutes(isSmsMode)} />
                )}
              />
              <div className={styles.version}>{`v${process.env.REACT_APP_VERSION}`}</div>
            </Sider>
          ) : null}
          <Layout className={styles.main}>
            {auth?.token ? <Header className={styles.header} /> : null}
            <Switch>
              {renderRouter}
              <Redirect to="/" />
            </Switch>
          </Layout>
        </Layout>
        <Modal
          title="双11降级通知"
          footer={null}
          visible={isShow1111 && (flagrInfo?.isFirstIn || visible)}
          maskClosable={false}
          onCancel={handleModalClose}
          width={960}
        >
          <p>
            2021年双11期间，整个淘宝平台将承受巨大流量压力，为保障核心功能的正常运行，将对微客全部功能进行降级调整。
          </p>
          <p>降级时间：</p>
          <p className={styles.bold}>2021/10/31 23:00--2021/11/01 9:00</p>
          <p className={styles.bold}>2021/11/10 23:00--2021/11/11 9:00</p>
          <p>感谢您理解与配合！</p>
        </Modal>
      </Spin>
    </Router>
  );
};

export default App;
